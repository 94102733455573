.roomsPage {
  background-color: #181818;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  text-align: center;
  h1 {
    text-align: center;
  }
}

.user {
  display: inline-block;
  padding: 0.5rem;

  img {
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    object-position: center;
    border-radius: 2rem;
  }

  p {
    margin: 0;
  }

//   animation: 1s ease-out 0s 1 slideInFromRight;
}

// @keyframes slideInFromRight {
//   0% {
//     transform: translateX(30rem);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }

.room {
  display: inline-block;
  vertical-align: top;
  overflow: auto;
  cursor: pointer;

  margin: 1rem;
  border-radius: 2rem;

  max-width: 30rem;
  min-width: 20rem;
  min-height: 15rem;

    width: 30rem;
    height: 20rem;

  background-color: #cccccc;
  background-position: center;
  background-repeat: none;
  background-size: cover;

  color: white;
  text-align: center;

  .inner {
    width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    padding: 1rem;
    border-radius: 2rem;
    backdrop-filter: blur(3px);
  }

  h2 {
    margin: 0;
  }
}
